import logo from "./logo.svg";
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import "./assets/css/colors/grape.css";
import "./App.css";

function App() {
  return (
    <div>
      <div>
        <div className="content-wrapper">
          <section
            className="wrapper image-wrapper bg-image bg-overlay bg-overlay-300"
            id="para1"
          >
            <div className="container pt-17 pb-19 pt-md-18 pb-md-17 text-center">
              <div className="row">
                <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto">
                  <h1 className="display-1 text-white fs-60 mb-4 px-md-15 px-lg-0">
                    Fight Capital <br />X <br />
                    <span className="underline-3 style-2 white">Hustler</span>
                  </h1>
                  <p className="lead fs-24 text-white lh-sm mb-7 mx-md-13 mx-lg-10">
                    Fight Capital NFTs turned to Memberships
                  </p>
                  <div>
                    <a
                      className="btn btn-white rounded mb-10 mb-xxl-5"
                      onClick={() => alert("Ticket Claimed")}
                    >
                      Claim Ticket
                    </a>
                  </div>
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container */}
            <div className="overflow-hidden">
              <div className="divider text-light mx-n2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 60">
                  <path
                    fill="currentColor"
                    d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z"
                  />
                </svg>
              </div>
            </div>
          </section>
          {/* /section */}
          <section className="wrapper bg-light">
            <div className="container pb-15 pb-md-17">
              <div className="row gx-md-5 gy-5 mt-n19 mb-14 mb-md-17">
                <div className="col-md-6 col-xl-3">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <img
                        src="https://secureservercdn.net/198.71.189.232/3vr.d7c.myftpupload.com/wp-content/uploads/2019/10/unnamed-11.jpg?time=1662928847"
                        className="solid-mono text-fuchsia mb-3"
                        width="100%"
                      />
                      <h4>About | Hustler Club</h4>
                      <p className="mb-2">
                        The Larry Flynt Hustler Club is a place where people can
                        go to have a good time and enjoy themselves. It has a
                        great atmosphere and is always packed with people. The
                        staff is friendly and willing to help you out, and
                        everything is top-notch. If you’re looking for a fun day
                        or fun night out, this place is definitely worth
                        checking out.
                      </p>
                      <a
                        href="https://hustlerlasvegas.com/"
                        className="more hover link-fuchsia"
                      >
                        Learn More
                      </a>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.card */}
                </div>
                {/*/column */}
                <div className="col-md-6 col-xl-3">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <img
                        src="https://secureservercdn.net/198.71.189.232/3vr.d7c.myftpupload.com/wp-content/uploads/2019/10/unnamed-10-1-1024x740.jpg"
                        className="solid-mono mb-3"
                        width="100%"
                      />
                      <h4>Overview | Hustler Club</h4>
                      <p className="mb-2">
                        In a city filled with excellent venues, it’s not easy
                        standing out, yet Hustler does. We’re not surprised by
                        the club’s success, though. It’s literally a gigantic
                        erotic castle dedicated to some of the best adult
                        entertainment around. Just driving by it, you might find
                        yourself staring at its gaudy exterior and wondering
                        what is inside.
                      </p>
                      <a
                        href="https://hustlerlasvegas.com/"
                        className="more hover link-violet"
                      >
                        Learn More
                      </a>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.card */}
                </div>
                {/*/column */}
                <div className="col-md-6 col-xl-3">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <img
                        src="https://www.fightcapital.io/wp-content/uploads/elementor/thumbs/Fight-Cap-NFT_FULL-LOGO-pl37ojhjpj0j51vwvt9e5m44za6ctsvxa3pd7c20b8.png"
                        className="solid-mono text-orange mb-3"
                        width="100%"
                      />
                      <h4>About | Fight Capital</h4>
                      <p className="mb-2">
                        Fight Capital is the exclusive NFT purveyor of top tier
                        talent in the real world of professional wrestling.
                        Fight Capital NFT has acquired both the NFT, and video
                        game rights to these legendary fighters. These NFTs
                        benefit these wrestlers, or their families directly.
                      </p>
                      <a
                        href="https://fightcapital.io"
                        className="more hover link-orange"
                      >
                        Learn More
                      </a>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.card */}
                </div>
                {/*/column */}
                <div className="col-md-6 col-xl-3">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <img
                        src="https://www.fightcapital.io/wp-content/uploads/2022/03/59990214-67F3-43B5-9739-BA3BC7815B89-1536x1536.png"
                        className="solid-mono text-green mb-3"
                        width="100%"
                      />
                      <h4>Utility | Fight Capital</h4>
                      <p className="mb-2">
                        Each character and rarity tier of NFT are designed to
                        offer unique perks. Each NFT is an access token for
                        exclusive IRL release parties, and for the Fight Capital
                        NFT Lounge located inside of The Nerd, Las Vegas. Our
                        team has real world partnerships and connections to many
                        different platforms, promotions, and businesses.
                      </p>
                      <a
                        href="https://fightcapital.io"
                        className="more hover link-green"
                      >
                        Learn More
                      </a>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.card */}
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
          </section>
          <hr />
        </div>
      </div>
    </div>
  );
}

export default App;
